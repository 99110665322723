$(function(){
  
  // $.removeCookie('section_cookie');

  if($.cookie('section_cookie') != 'set'){

    setTimeout(function(){
      $('.section-cookie__close').parent().slideDown();
    }, 5000);

  } 


  $('body').on('click', '.section-cookie__close', function(){

    $(this).parent().slideUp();

    $.cookie('section_cookie', 'set');

  });

});